/* eslint-disable no-negated-condition */
import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const uploadImage = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.ADD_IMAGE_TEXT}`,
    data: val
  });
  return response?.data;
};

const updateGeoLocation = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.UPDATE_GEO_LOCATION}`,
    data: val
  });
  return response?.data;
};

const getTotalNft = async (val) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.GET_TOTAL_NFT}`,
    data: val
  });
  return response?.data;
};

export {
  uploadImage,
  updateGeoLocation,
  getTotalNft,
}