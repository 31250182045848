import React, { useState } from 'react';
import { useSelector } from "react-redux";
import NatureTabs from "../../../components/common/natureTabs";
import DashboardBanner from "../dashboard/components/dashboardBanner";
import DashboardHeading from "../dashboard/components/dashboardHeading";
import DashboardSummary from "../dashboard/components/dashboardSummery";
import SmartContractModal from "../../../components/modal/smartContractModal";
import { TAB } from "../../constants/constants";
// import LockNftTable from './components/lockNftTable';
import LockImage from '../lockImage';

const UploadImage = () => {
  const { userDetail } = useSelector((state) => state.home);

  const [page, setPage] = useState(TAB.NATURE)
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);

  let isAdmin = userDetail?.isAdmin;

  return (
    <div className="energy-nft-items m-4 m-lg-5">
      <NatureTabs setPage={setPage} />
      <div className="tab-content" id="myTabContent">
        {
          page === TAB.NATURE &&
          <div className="card border-0">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="energy-nft-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                <DashboardBanner />
                <div className="service-content">
                  <DashboardHeading tabs="no-tab" tabSwitcher="no" />
                  {
                    !isAdmin &&
                    <>
                      <DashboardSummary isDashboardLoading={isDashboardLoading} setIsDashboardLoading={setIsDashboardLoading} />
                      <SmartContractModal />
                    </>
                  }
                  {/* <LockNftTable /> */}
                  <LockImage />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default UploadImage;