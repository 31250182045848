import "../../../assets/css/sidebar.css";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import NatureIcon from "../../../assets/icons/nature-icon.svg";
import NatureActiveIcon from "../../../assets/icons/nature-icon-active.svg";

const SideBar = () => {
  const { userDetail } = useSelector((state) => state.home);

  const page = window.location.pathname;
  let isAdmin = userDetail?.isAdmin;

  const redirectInTransactionHistory = () => {
    window.open(process.env.REACT_APP_TRANSACTION_URL, "_blank", "noreferrer");
  }

  return (
    <div className="side-bar-main text-center h-100">
      <div className="card h-100">
        <div className="card-body p-0">
          {
            isAdmin ?
              <nav className="nav flex-column">
                <NavLink className="nav-link font-5F738C" activeclassname="active" to="/upload-image">
                  <i className="fal fa-scroll-old font-30" style={{ transform: "ScaleY(-1)" }}></i>
                  <br />
                  <small className="font-12">Upload Image</small>
                </NavLink>
              </nav>
              :

              <nav className="nav flex-column">
                <NavLink className="nav-link font-5F738C" activeclassname="active" to="/dashboard">
                  <i className="fal fa-signal-alt-2 font-30"></i>
                  <br />
                  <small className="font-12">Dasboard</small>
                </NavLink>

                <NavLink className="nav-link font-5F738C" activeclassname="active" to="/wallet-address">
                  <i className="fal fa-wallet font-30"></i>
                  <br />
                  <small className="font-12">Wallet</small>
                </NavLink>

                <NavLink className="nav-link font-5F738C" activeclassname="active" to="/naturenft">
                  {page === "/naturenft" ? (
                    <img src={NatureActiveIcon} height={45} alt="" />
                  ) : (
                    <img src={NatureIcon} height={45} alt="" />
                  )}
                  <br />
                  <small className="font-12">Nature NFT</small>
                </NavLink>

                <NavLink className="nav-link font-5F738C" activeclassname="active" to="/upload-image">
                  <i className="fal fa-scroll-old font-30" style={{ transform: "ScaleY(-1)" }}></i>
                  <br />
                  <small className="font-12">Upload Image</small>
                </NavLink>

                <div className="nav-link font-5F738C my-3" onClick={() => redirectInTransactionHistory()}>
                  <i className="fal fa-sitemap font-30"></i>
                  <br />
                  <small className="font-12">Transaction History</small>
                </div>

                <NavLink className="nav-link font-5F738C" activeclassname="active" to="/about">
                  <i className="fal fa-info-circle font-30"></i>
                  <br />
                  <small className="font-12">About</small>
                </NavLink>
              </nav>
          }
        </div>
      </div>
    </div>
  );
};

export default SideBar;
