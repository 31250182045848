/* eslint-disable no-negated-condition */
import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const getTokenWiseAreaList = async (data) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.LOCATION_BY_SIZE}`,
    data: data
  });
  return response?.data;
};

const getNftSize = async (projectId) => {
  const response = await axios({
    method: "Get",
    url: `${api_end_point.BASE_URL}${api_end_point.GET_NFT_SIZE}/${projectId}`,
  });
  return response?.data;
};

export {
  getTokenWiseAreaList,
  getNftSize
}