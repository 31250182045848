import axios from "axios";
import api_end_point from "../../../constants/api_end_point";

const getLoginUserDetail = async (values) => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.LOGIN_USER_DETAILS}`,
    data: values
  });

  return response?.data;
};

const logout = async () => {
  const response = await axios({
    method: "Post",
    url: `${api_end_point.BASE_URL}${api_end_point.LOGOUT}`,
  });

  return response?.data;
}

export {
  getLoginUserDetail,
  logout,
}