export const homeConstants = {
  SET_USER_DETAIL: "SET_USER_DETAIL",
  SET_SMART_CONTRACT: "SET_SMART_CONTRACT",
  SET_METAMASK_INSTALLED_STATUS: "SET_METAMASK_INSTALLED_STATUS",
  SET_IS_WALLET_CONNECTED: "SET_IS_WALLET_CONNECTED",
  SET_IS_LOGGED_IN: "SET_IS_LOGGED_IN",
  SET_WALLET_ADDRESS: "SET_WALLET_ADDRESS",
  SET_SELECT_WALLET: "SET_SELECT_WALLET",
  SET_MAP_TOKEN: "SET_MAP_TOKEN",
}

const setUserDetail = (values) => {
  return {
    type: homeConstants.SET_USER_DETAIL,
    payload: values,
  };
};

const setSmartContract = (smartContract) => {
  return {
    type: homeConstants.SET_SMART_CONTRACT,
    payload: {
      smartContract,
    },
  };
};

const setIsMetamaskInstalled = (values) => {
  return {
    type: homeConstants.SET_METAMASK_INSTALLED_STATUS,
    payload: {
      status: values,
    },
  };
};

const setIsWalletConnected = (values) => {
  return {
    type: homeConstants.SET_IS_WALLET_CONNECTED,
    payload: {
      status: values,
    },
  };
};

const setUserLoginStatus = (values) => {
  return {
    type: homeConstants.SET_IS_LOGGED_IN,
    payload: {
      status: values,
    },
  };
};

const setUserWalletAddress = (values) => {
  return {
    type: homeConstants.SET_WALLET_ADDRESS,
    payload: {
      walletAddress: values,
    },
  };
};

const setSelectWallet = (wallet) => {
  return {
    type: homeConstants.SET_SELECT_WALLET,
    payload: {
      wallet,
    },
  };
};

const setMapToken = (tokens) => {
  return {
    type: homeConstants.SET_MAP_TOKEN,
    payload: {
      tokens,
    },
  };
};

export {
  setUserDetail,
  setSmartContract,
  setIsMetamaskInstalled,
  setIsWalletConnected,
  setUserLoginStatus,
  setUserWalletAddress,
  setSelectWallet,
  setMapToken,
}