export const messageConstant = {
  SET_SELECTED_CRYPTO: "SET_SELECTED_CRYPTO",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  SET_WALLET_DASHBOARD_DATA: "SET_WALLET_DASHBOARD_DATA",
  SET_MASTER_DATA: "SET_MASTER_DATA",
  SET_LOCK_ID: "SET_LOCK_ID",
  SET_ASSET_REQUEST: "SET_ASSET_REQUEST",
  SET_NFT_ID: "SET_NFT_ID",
};

const setSelectedCrypto = (values) => {
  return {
    type: messageConstant.SET_SELECTED_CRYPTO,
    payload: {
      selectedCrypto: values,
    },
  };
};

const setDashboardData = (values) => {
  return {
    type: messageConstant.SET_DASHBOARD_DATA,
    payload: {
      dashboard: values.dashboard,
      offSet: values.offSet,
    },
  };
};

const setUserDashboardData = (values) => {
  return {
    type: messageConstant.SET_WALLET_DASHBOARD_DATA,
    payload: {
      userDashboard: values,
    },
  };
};

const setMasterData = (values) => {
  return {
    type: messageConstant.SET_MASTER_DATA,
    payload: {
      masterData: values,
    },
  };
};

const setLock = (values) => {
  return {
    type: messageConstant.SET_LOCK_ID,
    payload: {
      lockId: values,
    },
  };
};

const setAssetRequest = (values) => {
  return {
    type: messageConstant.SET_ASSET_REQUEST,
    payload: {
      assetRequest: values,
    },
  };
};

const setNftId = (values) => {
  return {
    type: messageConstant.SET_NFT_ID,
    payload: {
      nftId: values,
    },
  };
};

export {
  setSelectedCrypto,
  setDashboardData,
  setUserDashboardData,
  setMasterData,
  setLock,
  setAssetRequest,
  setNftId,
};