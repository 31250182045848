import React from 'react';
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "../hooks/privateRoute";
import Dashboard from '../pages/dashboard';
import WalletAddress from '../pages/walletAddress';
import NatureNft from '../pages/naturenft';
import UploadImage from '../pages/uploadImage';
// import LockImage from '../pages/lockImage';
import About from "../pages/about";

const RoutesPage = () => {
  const { userDetail } = useSelector((state) => state.home);

  let isAdmin = userDetail?.isAdmin;

  return (
    <Routes>
      {
        isAdmin ?
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<UploadImage />} />
            <Route path="/upload-image" element={<UploadImage />} />
          </Route>
          :
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/wallet-address" element={<WalletAddress />} />
            <Route path="/naturenft" element={<NatureNft />} />
            <Route path="/upload-image" element={<UploadImage />} />
            {/* <Route path="/lock-map" element={<LockImage />} /> */}
            <Route path="/about" element={<About />} />
          </Route>
      }
    </Routes>
  )
}

export default RoutesPage;