import { messageConstant } from "./nftAction"

const initialState = {
  masterData: null,
  selectedCrypto: null,
  dashboard: null,
  userDashboard: null,
  offSet: null,
  lockId: null,
  nftId: null,
  assetRequest: null,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case messageConstant.SET_SELECTED_CRYPTO:
      return {
        ...state,
        selectedCrypto: action.payload.selectedCrypto,
      };
    case messageConstant.SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload.dashboard,
        offSet: action.payload.offSet,
      };
    case messageConstant.SET_WALLET_DASHBOARD_DATA:
      return {
        ...state,
        userDashboard: action.payload.userDashboard,
      };
    case messageConstant.SET_MASTER_DATA:
      return {
        ...state,
        masterData: action.payload.masterData,
      };
    case messageConstant.SET_LOCK_ID:
      return {
        ...state,
        lockId: action.payload.lockId,
      };
    case messageConstant.SET_ASSET_REQUEST:
      return {
        ...state,
        assetRequest: action.payload.assetRequest,
      };
    case messageConstant.SET_NFT_ID:
      return {
        ...state,
        nftId: action.payload.nftId,
      };
    default: return state;
  }
};

export default messageReducer;