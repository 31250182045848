/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
import "tippy.js/dist/tippy.css";
import React, { useEffect, useRef, useState } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import { useSelector, useDispatch } from "react-redux";
import Tippy from "@tippyjs/react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getTokenWiseAreaList, getNftSize } from "../services";
import infoIcon from "../../../../assets/images/information.png";
import { createAssetRequest, getPriceToken, getAvailableNft, getWalletBalance, acceptDisclaimer } from "../../nft/services";
import { copyText } from "../../../utils/copy";
import useAlert from "../../../hooks/useAlert";
import BuyTokenPopUp from "./buyTokenPopup";
import { TRANSACTION_TYPE } from "../../../constants/constants";
import TermsAndCondition from "../../../../components/modal/terms-and-condition";
import { setUserDetail } from "../../home/redux/homeAction";

const NftFormWithPredefineArea = ({ data, ddshow }) => {
  const timeout = useRef();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const { userDetail } = useSelector((state) => state.home);
  const { masterData, selectedCrypto } = useSelector((state) => state.nft);

  const [isLoading, setIsLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [transactionType, setTransactionType] = useState(TRANSACTION_TYPE.CRYPTO);
  const [selected, setSelected] = useState(data.currencyToken[0]);
  const [values, setValues] = useState({});
  const [submitFormIsLoading, setSubmitFormIsLoading] = useState(false);
  const [selectedPreDefineToken, setSelectedPreDefineToken] = useState(0);
  const [selectedAreaId, setSelectedAreaId] = useState();
  const [preDefineArea, setPreDefineArea] = useState([]);
  const [preDefineTokenOption, setPreDefineTokenOption] = useState([]);
  const [walletBalance, setWalletBalance] = useState(null);
  const [isTermsAndConditionModalOpen, setIsTermsAndConditionModalOpen] = useState(false);
  const [assetRequestData, setAssetRequestData] = useState(null);
  const [termsAndConditionIsLoading, setTermsAndConditionIsLoading] = useState(false);

  const isLoggedIn = userDetail?.user_name;
  const isKycDone = userDetail?.kyc_done;

  var map = "";
  // eslint-disable-next-line no-unused-vars
  var infoWindow = "";
  var selectedPolygon = null;
  var google = window.google;

  const handlePreDefineTokenSelectChange = (event) => {
    const selectedOption = JSON.parse(event.target.value);
    setSelectedPreDefineToken(selectedOption);
    getAreaList(selectedOption);
    setSelectedAreaId();
  }

  const getAreaList = async (selectedOption) => {
    const tokenLocationDetail = {
      token_id: selectedCrypto,
      size: selectedOption ? selectedOption : selectedPreDefineToken
    }
    const tokenAreaListRes = await getTokenWiseAreaList(tokenLocationDetail);
    if (tokenAreaListRes?.status) {
      if (tokenAreaListRes?.status) {
        if (tokenAreaListRes?.location) {
          let coordinateArr = [];
          tokenAreaListRes.location.forEach((ele) => {
            let coordinate = {
              tokenId: ele.id,
              coordinate: [
                { lat: ele.top_left_latitude, lng: ele.top_left_longitude },
                { lat: ele.top_left_latitude, lng: ele.bottom_right_longitude },
                { lat: ele.bottom_right_latitude, lng: ele.bottom_right_longitude },
                { lat: ele.bottom_right_latitude, lng: ele.top_left_longitude },
              ]
            }
            coordinateArr.push(coordinate);
          })
          setPreDefineArea(coordinateArr);
        }
      }
    }
  }

  useEffect(() => {
    getWithdrawalBalance();
  }, []);

  useEffect(() => {
    getAreaList();
    initMap();
    getNftSizeList();
    if (data.token !== "" && isLoading === true) {
      setIsLoading(false);
    }
    resetForm();
  }, [selectedCrypto]);

  useEffect(() => {
    initMap();
  }, [preDefineArea]);

  useEffect(() => {
    getTokenPrice();
  }, [selectedCrypto]);

  const initMap = () => {
    if (document.getElementById("map") == null) {
      checkMap();
      return false;
    }

    var myLatLng = preDefineArea?.length ? new google.maps.LatLng(preDefineArea[0]?.coordinate[0]?.lat, preDefineArea[0]?.coordinate[0]?.lng) : new google.maps.LatLng(33.5190755, -111.9253654);
    // The map, centered at Uluru
    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 22,
      center: myLatLng,
      mapTypeId: "terrain",
    });

    if (preDefineArea?.length) {
      preDefineArea.forEach((ele) => {
        // Construct the polygon.
        const polygon = new google.maps.Polygon({
          paths: ele.coordinate,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 3,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });

        google.maps.event.addListener(polygon, "click", function (event) {
          if (selectedPolygon) {
            // Reset the color of the previously selected polygon
            selectedPolygon.setOptions({ strokeColor: '#FF0000', fillColor: '#FF0000' });
          }
          setSelectedAreaId(ele.tokenId);
          formik.setFieldValue("token", selectedPreDefineToken);
          getTokenPrice();
          polygon.setOptions({ strokeColor: 'green', fillColor: 'green' });
          selectedPolygon = polygon;
        });
        polygon.setMap(map);
      });
    }

    infoWindow = new google.maps.InfoWindow();
  };

  const checkMap = () => {
    var chkMapInt = setInterval(function () {
      if (document.getElementById("map")) {
        clearInterval(chkMapInt);
        initMap();
      }
    }, 500);
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const showTermsAndConditionModal = () => {
    setIsTermsAndConditionModalOpen(true);
  };

  const hideTermsAndConditionModal = () => {
    setIsTermsAndConditionModalOpen(false);
  };

  const getTokenPrice = async (obj) => {
    const tokenDetail = {
      crypto_type_id: obj ? obj.id : selected.id,
      crypto_token_id: data.token.id,
      crypto_name: obj ? obj.name : selected.name,
      token: formik.values.token ? parseFloat(formik.values.token, 10) : parseFloat(selectedPreDefineToken, 10),
      crypto_amount: 0,
    };

    const result = await getPriceToken(tokenDetail);
    if (result.status) {
      formik.setFieldValue("token", result.token.toString());
      formik.setFieldValue("cryptoToken", (parseFloat(parseFloat(result.crypto_amount).toFixed(result?.crypto_name === "USDT" ? 6 : 18))).toString());
      formik.setFieldValue("usdPrice", result.usd_price);
    } else {
      formik.setFieldValue("token", "");
      formik.setFieldValue("cryptoToken", "");
      formik.setFieldValue("usdPrice", 0);
    }
    setCheck(true);
  };

  const handleTokenInput = () => {
    // Clear the previous timeout.
    clearTimeout(timeout.current);

    if (!formik?.values?.usdPrice || formik?.values?.usdPrice < masterData?.crypto_tokens?.find((ele) => ele.id === selectedCrypto).soft_limit) {
      setTransactionType(TRANSACTION_TYPE.CRYPTO);
    }

    timeout.current = setTimeout(() => {
      getTokenPrice();
    }, 600);
  };

  const handelAssetRequest = async (values) => {
    setSubmitFormIsLoading(true);
    if (isKycDone === true || isKycDone === "true") {
      const userBankDetail = userDetail?.user_bank_details;
      const walletAddress = userDetail?.wallet_address;
      const availableBalance = await getAvailableNft(data.token.id);

      if ((!userBankDetail?.account_number || userBankDetail?.account_number === "") && (!walletAddress || walletAddress === "")) {
        showAlert("ERROR", "Error", "Please update Wallet Address and Bank Detail for this user.");
      } else if (!walletAddress || walletAddress === "") {
        showAlert("ERROR", "Error", "Please update Wallet Address for this user.");
      } else if (!userBankDetail?.account_number || userBankDetail?.account_number === "") {
        showAlert("ERROR", "Error", "Please update Bank Detail for this user.");
      } else if (availableBalance?.available_nft < parseFloat(values.token, 10)) {
        showAlert("ERROR", "Error", `Please buy maximum ${availableBalance?.available_nft} token.`);
      } else if (parseFloat(values.token, 10) < masterData?.exchange_data?.find((ele) => ele.crypto_token_id === selectedCrypto)?.minimum_token) {
        showAlert("ERROR", "Error", `Minimum token to purchase is ${masterData?.exchange_data?.find((ele) => ele.crypto_token_id === selectedCrypto).minimum_token}.`);
      } else {
        const typeMaster = masterData.crypto_tokens;
        const typeToken = typeMaster?.filter(ele => ele?.id === selectedCrypto)[0]?.geofancing;
        let geoFancingId = [];

        typeToken && typeToken.map((val) => { geoFancingId.push({ nft_id: val.id }) });

        const cryptoDetail = {
          crypto_token_id: data.token.id,
          crypto_type_id: selected.id,
          // crypto_amount: parseFloat(values.cryptoToken, 10),
          currency_id: masterData.exchange_data[0]?.supported_fiat_currencies[0]?.id,
          token: parseFloat(values.token, 10),
          nft_details: geoFancingId,
        };

        if (transactionType === TRANSACTION_TYPE.CRYPTO) {
          cryptoDetail.crypto_amount = parseFloat(values.cryptoToken);
          cryptoDetail.transaction_type = "crypto";
        } else if (transactionType === TRANSACTION_TYPE.BANK) {
          cryptoDetail.bank_transfer_amount = values.usdPrice;
          cryptoDetail.transaction_type = "bank_transfer";
          cryptoDetail.transaction_id = values.transaction_id;
        } else if (transactionType === TRANSACTION_TYPE.SUNSET_WALLET) {
          cryptoDetail.sunset_wallet_amount = values.usdPrice;
          cryptoDetail.transaction_type = "sunset_wallet";
        }

        setAssetRequestData(cryptoDetail);
        if (userDetail?.disclaimer) {
          await generateAssetRequest(cryptoDetail);
        } else {
          showTermsAndConditionModal();
        }
      }
    } else {
      showAlert("ERROR", "Error", "Your KYC process not complete.");
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_KYC_VERIFICATION_URL;
      }, 2000);
    }
    setSubmitFormIsLoading(false);
  };

  useEffect(() => {
    let a;
    if (check) {
      a = setTimeout(() => {
        getTokenPrice();
      }, masterData.exchange_data[0].tsp_price_valid_in_minutes);
    }
    return () => {
      clearTimeout(a);
    };
  }, [selected]);

  useEffect(() => {
    handleTokenInput();
  }, [selectedPreDefineToken]);

  const handleSelectChange = (e) => {
    const obj = JSON.parse(e.target.value);
    setSelected(obj);
    getTokenPrice(obj);
  };

  const message = "On successful transaction, coins will be transferred to this account";

  const initialValues = {
    token: selectedPreDefineToken ? selectedPreDefineToken.toString() : "",
    cryptoToken: "",
  };

  const formSchema = Yup.object().shape({
    token: Yup.string()
      .trim()
      .required("Token cannot not be empty"),
    cryptoToken: Yup.string()
      .trim()
      .required("Crypto token cannot not be empty"),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await handelAssetRequest(values);
    },
  });

  const resetForm = () => {
    getNftSizeList();
    setSelectedAreaId();
    initMap();
    setTransactionType(TRANSACTION_TYPE.CRYPTO);
    formik.resetForm();
  }

  const getNftSizeList = async () => {
    const res = await getNftSize(selectedCrypto);
    if (res?.status) {
      let nftSize = [];
      res?.nfts_by_size?.forEach((ele) => {
        nftSize.push({ name: ele?.size, value: ele?.size })
      })
      nftSize.sort((a, b) => a.value - b.value);
      setPreDefineTokenOption(nftSize);
      setSelectedPreDefineToken(nftSize[0]?.value);
      getAreaList(nftSize[0]?.value);
    }
  }

  const getWithdrawalBalance = async () => {
    const walletAccessDetail = {
      user_id: userDetail?.multi_tsp?.wallet?.user_id,
      access_token: userDetail?.multi_tsp?.wallet?.access_token
    }

    const withdrawalBalance = await getWalletBalance(walletAccessDetail);
    if (withdrawalBalance?.status) {
      setWalletBalance(withdrawalBalance?.withdraw_amount);
    }
  }

  const acceptTermsAndCondition = async () => {
    setTermsAndConditionIsLoading(true);
    const disclaimerRes = await acceptDisclaimer({ disclaimer: true });
    if (disclaimerRes?.status) {
      setTermsAndConditionIsLoading(false);
      let loginUserDetail = userDetail;
      loginUserDetail["disclaimer"] = true;
      dispatch(setUserDetail(loginUserDetail));

      hideTermsAndConditionModal();
      await generateAssetRequest(assetRequestData);
    } else {
      setTermsAndConditionIsLoading(false);
      showAlert("ERROR", "Error", disclaimerRes.message);
    }
  }

  const generateAssetRequest = async (cryptoDetail) => {
    const res = await createAssetRequest(cryptoDetail);

    if (res.status) {
      setValues({
        assetRequestId: res.asset_request_id,
        paymentCurrency: res.payment_currency,
        qrCode: res.crypto_address,
        adminWallet: res.crypto_address,
        usdPrice: cryptoDetail.bank_transfer_amount,
        token: cryptoDetail.token,
        cryptoToken: cryptoDetail.crypto_amount,
        adminBankDetail: masterData?.admin_bank_details
      });

      if (transactionType === TRANSACTION_TYPE.CRYPTO || transactionType === TRANSACTION_TYPE.BANK) {
        showModal();
      }

      if (transactionType === TRANSACTION_TYPE.SUNSET_WALLET) {
        resetForm();
        getWithdrawalBalance();
        showAlert("SUCCESS", "Success", "Your transaction is successful.");
      }
    } else {
      showAlert("ERROR", "Error", res.message);
    }
  }

  return (
    <>
      <div className="mb-4 mb-lg-0">
        <div className="col-12 col-md-5">
          <label htmlFor="inputPassword4" className="form-label text-dark">Pre Define Token</label>
          <select className="form-select" onChange={handlePreDefineTokenSelectChange} value={selectedPreDefineToken} disabled={!preDefineTokenOption.length}>
            {preDefineTokenOption &&
              preDefineTokenOption.map((val, index) => (
                <option value={JSON.stringify(val.value)} key={index}>
                  {val.name}
                </option>
              ))}
          </select>
        </div>

        <div id="map" className="mfp-hide white-popup-block p-4 position-relative mt-5">
        </div>
      </div>

      {
        selectedAreaId &&
        <div className="row mt-5">
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <div className="form-box">
              {isLoading === false ? (
                <>
                  <form className="row g-3" onSubmit={formik.handleSubmit}>
                    <div className="col-12">
                      <label htmlFor="inputAddress" className="form-label text-dark">Your Wallet Address</label>
                      <Tippy content={message}>
                        <img src={infoIcon} alt="" className="info-icon" />
                      </Tippy>
                      <div className="input-group">
                        <input className="form-control" id="inputAddress" value={data?.walletAddress} disabled />
                        <div
                          className="input-group-text cursor-pointer"
                          onClick={() => {
                            copyText(data.walletAddress); showAlert("SUCCESS", "Success", "Address Copied");
                          }}
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-7">
                      <label htmlFor="inputEmail4" className="form-label text-dark">No Of Token</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          id="inputEmail4"
                          name="token"
                          onKeyUp={handleTokenInput}
                          onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
                          {...formik.getFieldProps("token")}
                          disabled
                        />
                      </div>
                      {formik.touched.token && formik.errors.token && (
                        <p className="text-danger">
                          <small>{formik.errors.token}</small>
                        </p>
                      )}
                      <p className="text-secondary">Minimum token to purchase is {masterData?.exchange_data?.find((ele) => ele.crypto_token_id === selectedCrypto).minimum_token}</p>
                    </div>
                    {ddshow ? (
                      <div className="col-12 col-md-5">
                        <label htmlFor="inputPassword4" className="form-label text-dark">Project Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          name="token"
                          value={masterData?.crypto_tokens?.filter(ele => ele.id === selectedCrypto)[0]?.token_code}
                          disabled
                        />
                        {/* <OtherNFTProjects image={true} /> */}
                      </div>
                    ) : (
                      ""
                    )}

                    {
                      formik.values.usdPrice && (formik.values.usdPrice <= walletBalance || formik.values.usdPrice >= masterData?.crypto_tokens?.find((ele) => ele.id === selectedCrypto).soft_limit) ?
                        <div className="col-12 col-md-6">
                          <div className="input-group">
                            <input type="radio" name="transferredByCrypro" checked={transactionType === TRANSACTION_TYPE.CRYPTO} onChange={() => setTransactionType(TRANSACTION_TYPE.CRYPTO)} />
                            <label htmlFor="inputEmail4" className="text-dark mx-1">
                              Transferred Via Crypto
                            </label>
                          </div>
                        </div> : ""
                    }
                    {
                      formik.values.usdPrice && formik.values.usdPrice >= masterData?.crypto_tokens?.find((ele) => ele.id === selectedCrypto).soft_limit ?
                        <div className="col-12 col-md-6">
                          <div className="input-group">
                            <input type="radio" name="transferredByBank" checked={transactionType === TRANSACTION_TYPE.BANK} onChange={() => setTransactionType(TRANSACTION_TYPE.BANK)} />
                            <label htmlFor="inputEmail4" className="text-dark mx-1">
                              Transferred Via Bank
                            </label>
                          </div>
                        </div> : ""
                    }
                    {
                      walletBalance && formik.values.usdPrice && formik.values.usdPrice <= walletBalance ?
                        <div className="col-12 col-md-6">
                          <div className="input-group">
                            <input type="radio" name="transferredBySunsetWallet" checked={transactionType === TRANSACTION_TYPE.SUNSET_WALLET} onChange={() => setTransactionType(TRANSACTION_TYPE.SUNSET_WALLET)} />
                            <label htmlFor="inputEmail4" className="text-dark mx-1">
                              Transferred Via Sunset Wallet
                            </label>
                          </div>
                        </div> : ""
                    }
                    <br />

                    {
                      transactionType === TRANSACTION_TYPE.BANK && formik.values.usdPrice >= masterData?.crypto_tokens?.find((ele) => ele.id === selectedCrypto).soft_limit ?
                        <div className="row mt-2">
                          <div className="col-12 col-md-6">
                            <label htmlFor="inputPassword4" className="form-label label-gr">
                              Bank Name: {masterData?.admin_bank_details?.bank_name}
                            </label>
                          </div>
                          <div className="col-12 col-md-6">
                            <label htmlFor="inputPassword4" className="form-label label-gr">
                              Account Number:  {masterData?.admin_bank_details?.account_number}
                            </label>
                          </div>
                          <div className="col-12 col-md-6 mt-0">
                            <label htmlFor="inputPassword4" className="form-label label-gr">
                              Swift Code:  {masterData?.admin_bank_details?.swift_code}
                            </label>
                          </div>
                          {
                            masterData?.admin_bank_details?.iban &&
                            <div className="col-12 col-md-6 mt-0">
                              <label htmlFor="inputPassword4" className="form-label label-gr">
                                IBAN:  {masterData?.admin_bank_details?.iban}
                              </label>
                            </div>
                          }
                          <div className="col-12 col-md-6 mt-0">
                            <label htmlFor="inputPassword4" className="form-label label-gr">
                              Pay:  ${formik?.values?.usdPrice ? formik.values.usdPrice : "0"}
                            </label>
                          </div>
                        </div>
                        :
                        transactionType === TRANSACTION_TYPE.SUNSET_WALLET && formik?.values?.usdPrice ?
                          <div className="row mt-2">
                            <div className="col-12 col-md-6">
                              <label htmlFor="inputPassword4" className="form-label label-gr">
                                Wallet Balance: {walletBalance}
                              </label>
                            </div>
                            <div className="col-12 col-md-6">
                              <label htmlFor="inputPassword4" className="form-label label-gr">
                                Pay:  ${formik?.values?.usdPrice ? formik.values.usdPrice : "0"}
                              </label>
                            </div>
                          </div>
                          :
                          <>
                            <div className="col-12 col-md-7">
                              <label htmlFor="inputEmail4" className="form-label text-dark">Pay</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="inputEmail4"
                                  name="cryptoToken"
                                  {...formik.getFieldProps("cryptoToken")}
                                />
                                {/* <div className="input-group-text bg-gr">
                            <img aria-hidden="true" height={20} src={baseURL + selected.img_src} alt="" />
                          </div> */}
                              </div>
                              {/* <p>Minimum token to purchase is {data.exchangeData.minimum_token}</p> */}
                              {
                                formik?.values?.usdPrice &&
                                <label htmlFor="inputEmail4" className="form-label label-green">
                                  USD price: ${formik?.values?.usdPrice ? formik.values.usdPrice : "0"}
                                </label>
                              }
                              {formik.touched.cryptoToken && formik.errors.cryptoToken && (
                                <p className="text-danger">
                                  <small>{formik.errors.cryptoToken}</small>
                                </p>
                              )}
                            </div>
                            <div className="col-12 col-md-5">
                              <label htmlFor="inputPassword4" className="form-label text-dark">Currency</label>
                              <select className="form-select" onChange={handleSelectChange}>
                                {data.currencyToken &&
                                  data.currencyToken.map((val, index) => (
                                    <option value={JSON.stringify(val)} key={index}>
                                      {val.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </>
                    }

                    <div className="col-12">
                      <div className="info-box">
                        <div className="info-img">
                          <img src={infoIcon} alt="" />
                        </div>
                        <div className="info-content">
                          {
                            transactionType === TRANSACTION_TYPE.CRYPTO &&
                            <p>
                              Please buy tokens worth at least ${masterData?.crypto_tokens?.find((ele) => ele.id === selectedCrypto).soft_limit} to use the bank transfer features.
                            </p>
                          }
                          {
                            transactionType === TRANSACTION_TYPE.BANK && formik.values.usdPrice >= masterData?.crypto_tokens?.find((ele) => ele.id === selectedCrypto).soft_limit &&
                            <>
                              <p>
                                Please transfer given USD price to the mentioned account details.
                              </p>
                              <p>
                                Please take note of your transaction ID after the completion of your bank transaction for the verification process.
                              </p>
                            </>
                          }
                          <p>
                            {
                              data?.exchangeData?.tsp_buy_screen_promotional_text
                                ? data.exchangeData?.tsp_buy_screen_promotional_text
                                : "Your total cost will be calculated based on the exchange rate at the moment when your transaction is confirmed Header"
                            }
                          </p>
                          <p>
                            {
                              data?.exchangeData?.tsp_buy_screen_footer_text
                                ? data.exchangeData?.tsp_buy_screen_footer_text
                                : "The Solar coins will appear in your account after the successful receipt of payment and approval by our team. Footer. The current price is valid for 3 minutes"
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {
                        submitFormIsLoading ?
                          <button type="submit" className="btn mx-auto" style={{ backgroundColor: "#186200", borderColor: "#186200" }} disabled>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </button>
                          :
                          <button type="submit" className="btn btn-darkgreen btn-extra-size mx-auto">
                            {isLoggedIn ? "Buy Tokens" : "Login"}
                          </button>
                      }
                    </div>
                  </form>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="result-box text-center">
              <h2 className="text-white">
                {message.cryptoPercent}% Discount for a limited period.
              </h2>
              <div className="form-discount" dangerouslySetInnerHTML={{ __html: message.cryptoMessage }} />
            </div>
          </div>
        </div>
      }
      <Modal backdrop="static" size="lg" scrollable={true} show={isTermsAndConditionModalOpen} onHide={hideTermsAndConditionModal}>
        <Modal.Body>
          <TermsAndCondition hide={hideTermsAndConditionModal} acceptTermsAndCondition={acceptTermsAndCondition} termsAndConditionIsLoading={termsAndConditionIsLoading} />
        </Modal.Body>
      </Modal>
      <Modal backdrop="static" size="lg" show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <BuyTokenPopUp data={values} wallet={data.walletAddress} hide={hideModal} transactionType={transactionType} resetForm={resetForm} />
        </Modal.Body>
      </Modal>
    </>
  );
}

// eslint-disable-next-line new-cap
export default React.memo(GoogleApiWrapper({ apiKey: "AIzaSyBbMCNnteP_t-kr5fuyQxHaY_vwEgdcF_E", libraries: ["drawing", 'places', 'visualization'] })(NftFormWithPredefineArea));
