import { paginationConstant } from "./paginationAction";

const initialState = {
  currentPage: "nature",
  pageNumber: 1,
};

const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case paginationConstant.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.currentPage,
      };
    case paginationConstant.SET_PAGE_NO:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };
    default:
      return state;
  }
};

export default paginationReducer;