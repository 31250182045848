/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import friendsIcon from "../../../assets/icons/friends.svg";
import { setSuccessMessage, setErrorMessage } from "../../../redux/commonRedux/messageRedux/messageAction";
import { handleLogout } from "../../utils/token";

const Profile = () => {
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.home);
  const { message, messageType } = useSelector((state) => state.message);

  const name = userDetail?.user_name;
  let isAdmin = userDetail?.isAdmin;
  const logoutDetail = {
    natureUserId: userDetail?.nature_user_id,
    natureAccessToken: userDetail?.nature_access_token
  };

  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_WALLET_URL}`;
  };

  const handleProfileRedirect = () => {
    window.location.href = `${process.env.REACT_APP_WALLET_URL}/profile`;
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setSuccessMessage());
      dispatch(setErrorMessage());
    }, 4000);
  }, [message]);

  return (
    <>
      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        {message && (
          <div className="notification" style={{ backgroundColor: messageType === "SUCCESS" ? "green" : "red", }}>
            {message}
          </div>
        )}
        <ul className="navbar-nav font-16 font-w-500 ">
          <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle variant="warning">
                <img src={friendsIcon} className="me-lg-2" alt="" style={{ height: "20px" }} />
                <span>{name}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {
                  !isAdmin &&
                  <>
                    <Dropdown.Item onClick={handleProfileRedirect}>
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleRedirect}>
                      Portfolio
                    </Dropdown.Item>
                  </>
                }
                <Dropdown.Item onClick={() => handleLogout(logoutDetail)}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
      <ul className="navbar-nav d-sm-inline-block d-md-none ms-md-auto">
        <li className="nav-item dropdown margin-left-0">
          <span className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src={friendsIcon} className="me-lg-2" alt="" />
            <span>{name}</span>
          </span>
          <ul className="dropdown-menu">
            <li className="d-flex margin-left-0" style={{ backgroundColor: "#186300" }}>
              <img src={friendsIcon} className="ms-2" width={20} alt="" />{" "}
              <small className="dropdown-item font-16" style={{ color: "#fff" }}>{name}</small>
            </li>
            <li className="margin-left-0">
              <span className="dropdown-item" onClick={() => handleProfileRedirect()}>Profile</span>
            </li>
            <li className="margin-left-0">
              <span className="dropdown-item" onClick={() => handleRedirect()}>Portfolio</span>
            </li>
            <li className="margin-left-0">
              <span className="dropdown-item" onClick={() => handleLogout(logoutDetail)}>Logout</span>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};

export default Profile;
