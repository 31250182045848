import { handleLogout, getQueryParams } from "../../app/utils/token";

export default function setupAxios(axios, store,) {
  axios.interceptors.request.use(
    config => {
      config.headers.auth_key = "C9941611DF64B";
      if (config?.data?.isFormData) {
        config.headers["content-type"] = "multipart/form-data";
        if (config?.method === "Post" || config?.method === "post" || config?.method === "POST") {
          const state = store.getState()
          const userDetail = state?.home?.userDetail;
          const userId = userDetail?.user_id;
          const accessToken = userDetail?.access_token;
          const routeData = getQueryParams();

          let data = {};
          if (!config?.data?.access_token && accessToken !== null) {
            if (config?.data) {
              data = { user_id: parseInt(userId, 10), access_token: accessToken, ...config.data }
            } else {
              data = { user_id: parseInt(userId, 10), access_token: accessToken }
            }
          } else if (!config?.data?.access_token && routeData?.access_token) {
            if (config?.data) {
              data = { user_id: parseInt(routeData?.user_id, 10), access_token: routeData?.access_token, ...config.data }
            } else {
              data = { user_id: parseInt(routeData?.user_id, 10), access_token: routeData?.access_token }
            }
          }

          let formData = new FormData();
          formData.append('user_id', data?.user_id);
          formData.append('access_token', data?.access_token);
          formData.append('text', data?.text);
          formData.append('image', data?.image);
          formData.append('token_map_id', data?.token_map_id);
          formData.append('geofencing_ids', data?.geofencing_ids);

          config.data = formData;
        }
      } else {
        config.headers["content-type"] = "text/plain";
        if (config?.method === "Post" || config?.method === "post" || config?.method === "POST") {
          const state = store.getState()
          const userDetail = state?.home?.userDetail;
          const userId = userDetail?.user_id;
          const accessToken = userDetail?.access_token;
          const routeData = getQueryParams();

          if (!config?.data?.access_token && accessToken !== null) {
            if (config?.data) {
              config.data = { user_id: parseInt(userId, 10), access_token: accessToken, ...config.data }
            } else {
              config.data = { user_id: parseInt(userId, 10), access_token: accessToken }
            }
          } else if (!config?.data?.access_token && routeData?.access_token) {
            if (config?.data) {
              config.data = { user_id: parseInt(routeData?.user_id, 10), access_token: routeData?.access_token, ...config.data }
            } else {
              config.data = { user_id: parseInt(routeData?.user_id, 10), access_token: routeData?.access_token }
            }
          }
        }
      }
      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    response => {
      return response
    },
    err => {
      if (err.response && err.response.status === 404) {
        const state = store.getState()
        const userDetail = state?.home?.userDetail;
        handleLogout({ natureUserId: userDetail?.nature_user_id, natureAccessToken: userDetail?.nature_access_token });
      }
      Promise.reject(err)
    }
  );
}
