import "../../../assets/css/dashboard-style.css";
import "../../../assets/css/hover.css";
import React, { useEffect, useState } from "react";
import NatureTabs from "../../../components/common/natureTabs";
import NatureNFTTabDashboard from "./components/naturenftTabContentDashboard";
import { TAB } from "../../constants/constants";

const NatureNft = () => {
  const [page, setPage] = useState(TAB.NATURE)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="energy-nft-items m-4 m-lg-5">
      <NatureTabs setPage={setPage} />
      <div className="tab-content" id="myTabContent">
        {
          page === TAB.NATURE &&
          <NatureNFTTabDashboard enegrynft="no" page={"nature"} />
        }
      </div>
    </div>
  );
};

export default NatureNft;
