import React from 'react';
import { NATURE_NFT_TAB } from '../../../constants/constants';

const NatureNftTab = ({ setPage }) => {

  const handleChange = (page) => {
    setPage(page);
  };

  return (
    <ul className="nav nav-tabs mb-3 gap-2 gap-lg-3 border-bottom-0" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active btn btn-primary"
          id="naturenft-tab"
          onClick={() => handleChange(NATURE_NFT_TAB.BUY_TOKEN)}
          data-bs-toggle="tab"
          data-bs-target="#naturenft"
          type="button"
          role="tab"
          aria-controls="naturenft"
          aria-selected="true"
        >
          Buy Token
        </button>
      </li>

      <li className="nav-item" role="presentation">
        <button
          onClick={() => handleChange(NATURE_NFT_TAB.BUY_TOKEN_WITH_PREDEFINE_AREA)}
          className="nav-link btn btn-primary"
          id="energynft-tab"
          data-bs-toggle="tab"
          data-bs-target="#energynft"
          type="button"
          role="tab"
          aria-controls="energynft"
          aria-selected="false"
        >
          Buy Token With Predefine Area
        </button>
      </li>
    </ul>
  );
}

export default NatureNftTab