/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable quote-props */
import "../../../assets/css/header.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCoordinates, unlockNFT, } from "../nft/services";
import UploadImageMapContainer from "./components/upload-image-map-container";

const UpdateImageForm = () => {
  const { selectedCrypto } = useSelector((state) => state.nft);
  // const { currentPage } = useSelector((state) => state.paginationReducer);
  const currentPage = "nature";
  const { tokens } = useSelector((state) => state.home);

  const [data, setData] = useState();
  const [available, setAvailable] = useState();
  const [sold, setSold] = useState();
  const [usernft, setUserNft] = useState();
  const [userGeoLocation, setUserGeoLocation] = useState();

  const getMapDetail = async () => {
    const data = {
      token_id: selectedCrypto,
      latitude2: "",
      longitude2: "",
      longitude1: "",
      latitude1: "",
    };
    const val = {
      crypto_token_id: selectedCrypto,
    };
    setData();
    setAvailable();
    setSold();
    setUserNft();
    setUserGeoLocation();
    const res = currentPage === "nature" ? await getCoordinates(data) : await unlockNFT(val);
    if (res?.geofencing) {
      setData(res.geofencing.available);
      setAvailable(res.geofencing.reserve);
      setSold(res.geofencing.sold);
      setUserNft(res.geofencing.user_nft);
      setUserGeoLocation(res.geofencing.user_geo_location);
    } else if (res.success !== undefined && res.success) {
      setUserNft(res.user_nft);
    }
  };

  useEffect(() => {
    getMapDetail();
  }, [selectedCrypto]);

  return (
    <>
      {data && data.length ? (
        <div className="mx-1 mx-md-5 my-4">
          <UploadImageMapContainer
            data={data}
            reserve={available}
            sold={sold}
            tokenId={selectedCrypto}
            tokens={tokens}
            usernft={usernft}
            userGeoLocation={userGeoLocation}
            currentPage={currentPage}
            getMapDetail={getMapDetail}
          />
        </div>
      ) : usernft && usernft.length ? (
        <div className=" mx-1 mx-md-5 my-4">
          <UploadImageMapContainer
            data={data}
            reserve={available}
            sold={sold}
            tokenId={selectedCrypto}
            tokens={usernft.length}
            usernft={usernft}
            userGeoLocation={userGeoLocation}
            currentPage={currentPage}
            getMapDetail={getMapDetail}
          />
        </div>
      ) : (
        <>
          <div className="text-center font-30 font-000000">
            No NFT Available
          </div>
        </>
      )}
    </>
  );
};

export default UpdateImageForm;
