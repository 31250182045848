import { getLoginUserDetail } from "../pages/home/services/home";
// import { getLastTSPDetail } from "../utils/token";

const checkLogin = async (data) => {
  const res = await getLoginUserDetail(data);
  if (res?.status) {
    let loginUserDetail = {
      user_name: res?.user_name,
      user_email: res?.user_email,
      kyc_done: res?.kyc_done,
      wallet_address: res?.tsp?.wallet_address,
      status: res?.status,
      mfa_status: res?.tsp?.mfa_status,
      aws_access_token: res?.tsp?.aws_access_token,
      aws_session: res?.tsp?.aws_session,
      user_id: res?.tsp?.user_id,
      access_token: res?.tsp?.access_token,
      disclaimer: res?.tsp?.disclaimer,
      isAdmin: res?.tsp?.is_admin ? res.tsp.is_admin : false,
      cash_decimal_limit: res.cash_decimal_limit,
      crypto_decimal_limit: res.crypto_decimal_limit,
      multi_tsp: res?.multi_tsp,
      user_bank_details: { ...res?.multi_tsp?.wallet?.bank_detail, account_holder_name: res.user_name },
    }
    return { status: true, loginUserDetail };
  }
};

export {
  checkLogin
};
